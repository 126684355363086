import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Product from '../components/Product'
import { listProducts } from '../actions/productActions'
import MainSection from '../components/MainSection'
import ShopByCategory from '../components/ShopByCategory'
import { Link } from 'react-router-dom'
import Paginate from '../components/Paginate'
import TopRatedProducts from '../components/TopRatedProducts'
import LatestProducts from '../components/LatestProducts'
import Slider from '../components/Slider/Slider'

const HomeScreen = ({ match }) => {
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, links } = productList

  useEffect(() => {
    dispatch(listProducts(keyword, '', pageNumber))
  }, [dispatch, keyword, pageNumber])

  return (
    <>
      <Slider /> 
      <ShopByCategory />    
      <TopRatedProducts />
      <LatestProducts />
    </>
  )
}

export default HomeScreen

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getSubCategoriesWithCategroyName } from '../actions/categoryActions'
import { BASE_URL } from '../constants/globalConstants'
import Loader from './Loader'
import Message from './Message'

const ShopByCategory = () => {
  const dispatch = useDispatch()

  const subCategoriesList = useSelector((state) => state.subCategoriesList)
  const { loading, error, subCategories } = subCategoriesList

  useEffect(() => {
    dispatch(getSubCategoriesWithCategroyName('BuyEthiopia'))
  }, [dispatch])
  return (
    <div>
      <div className='container mt-5'>
        <div className='row h-100'>
          <div className='col-lg-7 mx-auto text-center mb-6'>
            <h5 className='fw-bold fs-3 fs-lg-5 lh-sm mb-3'>
              Shop By Category
            </h5>
          </div>
          <div className='col-12'>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <nav>
                <div
                  className='nav nav-tabs majestic-tabs mb-4 justify-content-center'
                  id='nav-tab'
                  role='tablist'
                >
                  {subCategories.map((subCategory) => (
                    <button
                      key={subCategory.category_id}
                      className={`nav-link ${
                        subCategory.name === 'Women' ? 'active' : ''
                      }`}
                      id={`nav-${subCategory.name}-tab`}
                      data-bs-toggle='tab'
                      data-bs-target={`#nav-${subCategory.name}`}
                      type='button'
                      role='tab'
                      aria-controls={`nav-${subCategory.name}`}
                      aria-selected='true'
                    >
                      {subCategory.name}
                    </button>
                  ))}
                </div>
                <div className='tab-content' id='nav-tabContent'>
                  {subCategories.map((subCategory) => (
                    <div
                      key={subCategory.category_id}
                      className={`${
                        subCategory.name === 'Women' ? 'tab-pane fade show active' : 'tab-pane fade'
                      }`}
                      id={`nav-${subCategory.name}`}
                      role='tabpanel'
                      aria-labelledby={`nav-${subCategory.name}-tab`}
                    >
                      <div
                        className='nav nav-pills justify-content-center mb-5'
                        id={`pills-tab-${subCategory.name}`}
                        role='tablist'
                      >
                        {subCategory.childrens.map((child) => (
                          <div
                            className=''
                            key={child.category_id}
                            role='presentation'
                          >
                            <div className='card card-span text-white m-2'>
                              <img
                                className='img-fluid'
                                src={`${BASE_URL}${child.image}`}
                                // width='200'
                                // height='200'
                                alt='...'
                                style={{ height: '220px', width: '220px' }}
                              />
                              <div className='card-img-overlay d-flex flex-center'>
                                <Link
                                  className='btn btn-lg btn-light mt-9'
                                  to={`/category/${child.category_id}`}
                                >
                                  {child.name}
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopByCategory

import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import Loader from './Loader'
import Product from './Product'
import { listLatestProducts } from '../actions/productActions'
import { Link } from 'react-router-dom'

const LatestProducts = () => {
    const dispatch = useDispatch()

    const topLatestProductList = useSelector((state) => state.topLatestProductList)
    const {loading, error, topLatestProducts} = topLatestProductList
    
    useEffect(() => {
        dispatch(listLatestProducts())
    }, [dispatch])
  return  <div>
  {loading ? (
    <div><Loader /></div>        
  ) : error ? (
    <div> <Message variant='danger'>{error}</Message></div>       
  ) : (
       
      <div>
      <h4>Latest Products</h4>
      <div className='row'> 
      {topLatestProducts.map((product) => (            
        <div
          key={product.product_id}
          className='col-sm-12 col-md-6 col-lg-3 col-xl-3'
        >
          <Product product={product} />
        </div>   
      ))} 
       </div>
       <div className='text-center'>
        <Link to={'products'} className='btn btn-dark'>Browse All</Link>
       </div>
      </div>
 
  )}
  </div>;
};

export default LatestProducts;

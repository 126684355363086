import { BrowserRouter as Router, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import OrderListScreen from './screens/OrderListScreen'
import ProductListScreen from './screens/ProductListScreen'
import CreateProductScreen from './screens/CreateProductScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import CategoryScreen from './screens/CategoryScreen'
import ProductsScreen from './screens/ProductsScreen'

const App = () => {
  const { t } = useTranslation()
  return (
    <>
      <Router>
        <Header />
        <div className='container'>
          <div className='d-flex justify-content-end'></div>
          <div className='d-flex flex-column align-items-start'>
            {/* <h1 className='font-weight-normal mb-3'>{t('welcome_message')}</h1> */}
          </div>
          <Route path='/products' component={ProductsScreen} />
          <Route path='/login' component={LoginScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/shipping' component={ShippingScreen} />
          <Route path='/payment' component={PaymentScreen} />
          <Route path='/placeorder' component={PlaceOrderScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/order' component={OrderListScreen} />
          <Route path='/order/:id' component={OrderScreen} />
          {/* <Route path='/productlist' component={ProductListScreen} />
          <Route path='/products/:id/edit' component={ProductEditScreen} /> */}
          <Route path='/product/:id' component={ProductScreen} />
          <Route path='/category/:categoryId' component={CategoryScreen} />
          <Route path='/createproduct' component={CreateProductScreen} />
          <Route path='/cart/:id?' component={CartScreen} />
          <Route path='/search/:keyword' component={ProductsScreen} />
          <Route
            path='/search/:keyword/page/:pageNumber'
            component={ProductsScreen}
            exact
          />
          <Route path='/page/:pageNumber' component={ProductsScreen} exact />
          <Route path='/' component={HomeScreen} exact />
        </div>
        <Footer />
      </Router>
    </>
  )
}

export default App

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'
import { useForm } from 'react-hook-form'

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)

  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (data) => {
    dispatch(login(data.email, data.password))
  }
  return (
    <FormContainer>
      <h1>Sign In</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='form-group'>
          <label htmlFor='email'>Email Address</label>
          <input
            type='email'
            className={`form-control my-1 ${errors.email && 'is-invalid'}`}
            placeholder='Enter email'
            // value={email}
            {...register('email', {required: true, type: 'email'})}
            // onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <span className='invalid-feedback'>This email field is required</span>}
        </div>
        <div className='form-group'>
          <label htmlFor='password'>Password</label>
          <input
            type='password'
            className={`form-control my-1 ${errors.password && 'is-invalid'}`}
            placeholder='Enter password'
            // value={password}
            // onChange={(e) => setPassword(e.target.value)}
            {...register('password', {required: true})}
          />
          {errors.password && <span className='invalid-feedback'>This password field is required</span>}
        </div>
        <button className='btn btn-dark my-2'>Sign In</button>
        <div className='row py-3'>
          <div className='col'>
            Create new account.{' '}
            <Link
              to={redirect ? `/register?redirect=${redirect}` : '/register'}
            >
              Register
            </Link>
          </div>
        </div>
      </form>
    </FormContainer>
  )
}

export default LoginScreen

import React, {useEffect} from 'react'
import { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllCategories } from '../actions/categoryActions'
import {listProducts} from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Product from '../components/Product'

const ProductsScreen = ({match}) => {
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()

  const [selectedCategories, setSelectedCategories] = useState([])

  useEffect(() => {
    dispatch(listProducts(keyword, selectedCategories, pageNumber))
    dispatch(getAllCategories())
  }, [dispatch, keyword, selectedCategories, pageNumber])

  const productList = useSelector((state) => state.productList)
  const {loading, error, products} = productList

  const allCategoriesList = useSelector((state) => state.allCategoriesList)
  const { loading: allCategoriesListLoading, error: allCategoriesListError, allCategories } = allCategoriesList

  const onChangeCategoryCheckbox = (e) => {
    const isChecked = e.target.checked
    if(isChecked) {
      selectedCategories.push(e.target.value)
      console.log('selectedCategories', selectedCategories);
      dispatch(listProducts(keyword, selectedCategories, pageNumber))
    } else {
      const index = selectedCategories.indexOf(e.target.value)
      selectedCategories.splice(index, 1)
      setSelectedCategories(selectedCategories)
      console.log('selectedCategories', selectedCategories);
      dispatch(listProducts(keyword, selectedCategories, pageNumber))
    }
  }

  return (
       
      <div>
      <h4 className='lead'>Products</h4>
      <div className='row'>
        <div className="col-md-3 col-xl-3 col-sm-12">
          {allCategoriesListLoading && <Loader />}
          {allCategoriesListError && <Message variant='danger'>{error}</Message>}
          {allCategories.length > 0 && !allCategoriesListLoading && !allCategoriesListError && <div>
            {allCategories.map((category, index) => (
              <div key={index} class="accordion d-none d-sm-none d-md-block" id={`accordion${index}`}>
              <div class="accordion-item">
                <h2 class="accordion-header" id={`heading${index}`}>
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                    {category.name}
                  </button>
                </h2>
                <div id={`collapse${index}`} class="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent={`#accordion${index}`}>
                  <div class="accordion-body">
                    {category.childrens.length > 0 && category.childrens.map((child, index) => (
                      <div key={index}>
                        <div className="d-flex gap-2 h6">
                          <input type="checkbox" id={`checkbox${index}`} value={child.category_id} onChange={ (e) => onChangeCategoryCheckbox(e) } /> <label htmlFor={`checkbox${index}`}>{child.name}</label> 
                        </div>                       
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div> 
            ))}
          </div> }

        </div> 
        <div className="col-md-9 col-xl-9 col-sm-12">
          <div className="row">
        {loading && <div><Loader /></div>}   
        {error &&   <div> <Message variant='danger'>{error}</Message></div> }      
        {products?.length > 0 && !loading && !error && products.map((product) => (            
              <div
                key={product.product_id}
                className='col-sm-12 col-md-6 col-lg-4 col-xl-4'
              >
                <Product product={product} />
              </div>   
            ))}
            {!loading && !error && products?.length === 0 &&  
              <div>
                <Message variant='info'>No products found</Message>
              </div>
          }         
        </div>      
       </div> 
       </div>
       </div>
  )
}

export default ProductsScreen
import React, { useEffect }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listTopProducts } from '../actions/productActions'
import Loader from './Loader'
import Message from './Message'
import Product from './Product'

const TopRatedProducts = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listTopProducts())
    }, [dispatch])

    const productsTopRated = useSelector((state) => state.productsTopRated)
    const {topProducts, loading, error} = productsTopRated

    return (
      <section className='py-0'>
        <div className='container'>
          <div className='row h-100'>
            <div className='col-lg-7 mx-auto text-center mb-6'>
              <h5 className='fs-3 fs-lg-5 lh-sm mb-3'>Checkout Top Rated Products</h5>
            </div>
            <div className='col-12'>                            
                    <div className='row'>
                    {loading ? (
                        <div><Loader /></div>        
                      ) : error ? (
                        <div> <Message variant='danger'>{error}</Message></div>       
                      ) : (topProducts.map(topProduct => (
                        <div
                          key={topProduct.product_id}
                          className='col-sm-12 col-md-6 col-lg-3 col-xl-3'
                        >
                          <Product product={topProduct} />
                        </div>
                      )) )}
                                           
                    </div>    
            </div>
          </div>
        </div>
      </section>
    )
}

export default TopRatedProducts

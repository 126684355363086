import axios from "axios"
import { BASE_URL } from "../constants/globalConstants"
import { CITIY_LIST_WITH_COUNTRY_FAIL, CITIY_LIST_WITH_COUNTRY_REQUEST, CITIY_LIST_WITH_COUNTRY_SUCCESS, COUNTRY_LIST_FAIL, COUNTRY_LIST_REQUEST, COUNTRY_LIST_SUCCESS, PAYMENT_METHOD_LIST_FAIL, PAYMENT_METHOD_LIST_REQUEST, PAYMENT_METHOD_LIST_SUCCESS } from "../constants/sharedConstants"


export const listCountries = () => async (dispatch) => {       
    try {
        dispatch({
            type: COUNTRY_LIST_REQUEST,
        })

        const config = {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }

        const { data } = await axios.get(`${BASE_URL}/api/countries`, config)

        dispatch({
        type: COUNTRY_LIST_SUCCESS,
        payload: data,
        })
    } catch (error) {
        dispatch({
        type: COUNTRY_LIST_FAIL,
        payload: error.message,
        })
    }
}

export const listCityWithCountry = (countryId) => async (dispatch) => {
    try {
        dispatch({
            type: CITIY_LIST_WITH_COUNTRY_REQUEST
        })

        const config = {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }

        const { data } = await axios.get(`${BASE_URL}/api/cities/${countryId}`, config)

        dispatch({
        type: CITIY_LIST_WITH_COUNTRY_SUCCESS,
        payload: data,
        })

    } catch (error) {
        dispatch({
            type: CITIY_LIST_WITH_COUNTRY_FAIL,
            payload: error.message,
            })
    }
}

export const listPaymentMethods = () => async (dispatch) => {       
    try {
        dispatch({
            type: PAYMENT_METHOD_LIST_REQUEST,
        })

        const config = {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }

        const { data } = await axios.get(`${BASE_URL}/api/paymentmethods`, config)

        dispatch({
        type: PAYMENT_METHOD_LIST_SUCCESS,
        payload: data,
        })
    } catch (error) {
        dispatch({
        type: PAYMENT_METHOD_LIST_FAIL,
        payload: error.message,
        })
    }
}
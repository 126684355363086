import axios from 'axios'
import {
  GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_REQUEST,
  GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_SUCCESS,
  GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_FAIL,
  GET_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST,
  GET_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS,
  GET_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL,
  GET_CATEGORY_PRODUCTS_REQUEST,
  GET_CATEGORY_PRODUCTS_SUCCESS,
  GET_CATEGORY_PRODUCTS_FAIL,
  GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST,
  GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS,
  GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL,
  GET_ALL_CATEGORIES_LIST_REQUEST,
  GET_ALL_CATEGORIES_LIST_SUCCESS,
  GET_ALL_CATEGORIES_LIST_FAIL,
} from '../constants/categoryConstants'
import { BASE_URL } from '../constants/globalConstants'

export const getSubCategoriesWithCategroyName =
  (categoryName) => async (dispatch) => {
    try {
      dispatch({ type: GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_REQUEST })

      const { data } = await axios.get(`${BASE_URL}/api/subcategories/${categoryName}`)

      dispatch({
        type: GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getSubCategoriesWithCategoryId =
  (categoryId) => async (dispatch) => {
    try {
      dispatch({ type: GET_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST })

      const { data } = await axios.get(
        `${BASE_URL}/api/subcategories/${categoryId}/withcategoryid`
      )

      dispatch({
        type: GET_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getSubSubCategoriesWithCategoryId =
  (categoryId) => async (dispatch) => {
    try {
      dispatch({ type: GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST })

      const { data } = await axios.get(
        `${BASE_URL}/api/subcategories/${categoryId}/withcategoryid`
      )

      dispatch({
        type: GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getCategoryProducts = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CATEGORY_PRODUCTS_REQUEST })

    const { data } = await axios.get(`${BASE_URL}/api/categories/${categoryId}/products`)

    dispatch({
      type: GET_CATEGORY_PRODUCTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_CATEGORY_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CATEGORIES_LIST_REQUEST })

    const { data } = await axios.get(`${BASE_URL}/api/get_all_categories`)

    dispatch({
      type: GET_ALL_CATEGORIES_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_ALL_CATEGORIES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

import React from 'react'
import { Link } from 'react-router-dom'

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <nav className='navbar navbar-expand-sm justify-content-center mb-4 d-none d-md-block'>
      <ul className='navbar-nav'>
        <li className='nav-item'>
          {step1 ? (
            <Link className='nav-link text-dark' to='/login'>
              Sign Up
            </Link>
          ) : (
            <Link className='nav-link disabled' to='/login'>
              Sign Up
            </Link>
          )}
        </li>

        <li className='nav-item'>
          {step2 ? (
            <Link className='nav-link text-dark' to='/shipping'>
              Shipping
            </Link>
          ) : (
            <Link className='nav-link disabled' to='/shipping'>
              Shipping
            </Link>
          )}
        </li>

        <li className='nav-item'>
          {step3 ? (
            <Link className='nav-link text-dark' to='/payment'>
              Payment
            </Link>
          ) : (
            <Link className='nav-link disabled' to='/payment'>
              Payment
            </Link>
          )}
        </li>

        <li className='nav-item'>
          {step4 ? (
            <Link className='nav-link text-dark' to='/placeorder'>
              Place Order
            </Link>
          ) : (
            <Link className='nav-link disabled' to='/placeorder'>
              Place Order
            </Link>
          )}
        </li>
      </ul>
    </nav>
  )
}

export default CheckoutSteps

import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productDetailsReducer,
  userProductListReducer,
  productDeleteReducer,
  productReviewCreateReducer,
  categoryListReducer,
  subCategoryListReducer,
  productCreateReducer,
  productTopRatedReducer,
  topLatestProductListReducer,
  relatedProductListReducer,
  adProductListReducer
} from './reducers/productReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderListReducer,
} from './reducers/orderReducers'

import { cartReducer } from './reducers/cartReducers'
import {
  allCategoriesListReducer,
  subCategoriesListReducer,
  subSubCategoriesListReducer,
} from './reducers/categoryReducers'
import { cityListWithCountryReducer, countryListReducer, paymentMethodListReducer } from './reducers/sharedReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productReviewCreate: productReviewCreateReducer,
  productsTopRated: productTopRatedReducer,
  topLatestProductList: topLatestProductListReducer,
  relatedProductList: relatedProductListReducer,
  adProductList: adProductListReducer,
  userProductList: userProductListReducer,
  categoryList: categoryListReducer,
  subCategoryList: subCategoryListReducer,
  subCategoriesList: subCategoriesListReducer,
  subSubCategoriesList: subSubCategoriesListReducer,
  allCategoriesList: allCategoriesListReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderList: orderListReducer,
  countryList: countryListReducer,
  cityListWithCountry: cityListWithCountryReducer,
  paymentMethodList: paymentMethodListReducer
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: {
    userInfo: userInfoFromStorage,
  },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store

import React from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookie from 'js-cookie'
import GlobIcon from './GlobIcon'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'am',
    name: 'አማርኛ',
    country_code: 'et',
  },
  {
    code: 'or',
    name: 'oromifa',
    country_code: 'et',
  },
]

const Header = () => {
  const currentLanguageCode = cookie.get('i18next') || 'en'
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const logoutHandler = () => {
    dispatch(logout())
  }
  return (
    <header>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <div className='container'>
          <Link to='/' className='navbar-brand'>
            {/* <img
              src='/jeba.png'
              // className='img img-fluid'
              style={{ width: '50px', height: '50px' }}
              alt='Jeba Mega Store'
            /> */}
              <img
              src='/caremdstore.png'
              // className='img img-fluid'
              style={{ width: '150px', height: '50px' }}
              alt='Care MD Store'
            />
          </Link>
          <button
            className='navbar-toggler mb-2'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <Route render={({ history }) => <SearchBox history={history} />} />
            {/* <form className='d-flex'> */}
            {/* <select
                className='form-select ms-4 me-1'
                aria-label='category-select'
              >
                <option disabled selected>
                  Category
                </option>
                <option value='1'>One</option>
                <option value='2'>Two</option>
                <option value='3'>Three</option>
              </select> */}
            {/* <input
              className='form-control me-2'
              type='search'
              placeholder='Search'
              aria-label='Search'
            />
            <button className='btn btn-outline-dark' type='submit'>
              Search
            </button> */}
            {/* </form> */}
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              {/* <li className='nav-item'>
                <Link to='/' className='nav-link'>
                  <i className='fas fa-home'></i> Home
                </Link>
              </li> */}
               <li className='nav-item'>
                <Link to='/products' className='nav-link'>
                 Products
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/cart' className='nav-link'>
                  <i className='fas fa-shopping-cart'></i> Cart (
                  {cartItems.length})
                </Link>
              </li>
              {userInfo ? (
                <li className='nav-item dropdown'>
                  <button
                    className='btn btn-link nav-link dropdown-toggle'
                    type='button'
                    id='dropdownMenuButton2'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    {userInfo.user.name}
                  </button>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuButton2'
                  >
                    <li className='dropdown-item'>
                      <Link to='/profile' className='dropdown-item'>
                        Profile
                      </Link>
                    </li>
                    <li className='dropdown-item'>
                      <Link to='/order' className='dropdown-item'>
                        My Orders
                      </Link>
                    </li>
                    {/* <li className='dropdown-item'>
                      <Link to='/productlist' className='dropdown-item'>
                        My Products
                      </Link>
                    </li> */}
                    <li className='dropdown-item'>
                      <button onClick={logoutHandler} className='dropdown-item'>
                        Logout
                      </button>
                    </li>
                  </ul>
                </li>
              ) : (
                <>
                  <li className='nav-item'>
                    <Link to='/login' className='nav-link'>
                      <i className='fas fa-user'></i> Sign In
                    </Link>                  
                  </li>
                  {/* <li className='nav-item'>
                    <Link to='/register' className='nav-link'>
                    <i className="fas fa-user-plus"></i> Sign Up
                    </Link>
                  </li> */}
                </>               
              )}

              {/* <li className='nav-item dropdown'>
                <button
                  className='btn btn-link nav-link dropdown-toggle'
                  type='button'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <GlobIcon />
                </button>
                <ul
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuButton1'
                >
                  <li>
                    <span className='dropdown-item-text'>{t('language')}</span>
                  </li>
                  {languages.map(({ code, name, country_code }) => (
                    <li key={code}>
                      <button
                        className='dropdown-item'
                        onClick={() => i18next.changeLanguage(code)}
                        disabled={code === currentLanguageCode}
                      >
                        <span
                          className={`flag-icon flag-icon-${country_code} mx-2`}
                          style={{
                            opacity: code === currentLanguageCode ? 0.5 : 1,
                          }}
                        ></span>
                        {name}
                      </button>
                    </li>
                  ))}
                </ul>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header

import React from "react";
import "./Slider.css";
import leftArrow from "./icons/left-arrow.svg";
import rightArrow from "./icons/right-arrow.svg";

export default function BtnSlider({ direction, moveSlide, disable }) {

  return (
    <button
      onClick={moveSlide}
      className={direction === "next" ? "btn-slide next" : "btn-slide prev"}
      disabled={disable}
      style={{'display': disable ? 'none' : 'block'}}
    >
      <img src={direction === "next" ? rightArrow : leftArrow} alt={`${direction === "next" ? rightArrow : leftArrow}`} />
    </button>
  );
}

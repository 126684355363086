import { CITIY_LIST_WITH_COUNTRY_FAIL, CITIY_LIST_WITH_COUNTRY_REQUEST, CITIY_LIST_WITH_COUNTRY_SUCCESS, COUNTRY_LIST_FAIL, COUNTRY_LIST_REQUEST, COUNTRY_LIST_SUCCESS, PAYMENT_METHOD_LIST_FAIL, PAYMENT_METHOD_LIST_REQUEST, PAYMENT_METHOD_LIST_SUCCESS } from "../constants/sharedConstants"

export const countryListReducer = (state = {countries: []}, action) => {
    switch (action.type) {
        case COUNTRY_LIST_REQUEST:
          return { loading: true, countries: [] }
        case COUNTRY_LIST_SUCCESS:
          return {  loading: false,  countries: action.payload }
        case COUNTRY_LIST_FAIL:
          return { loading: false, error: action.payload }
        default:
          return state
      }
}

export const cityListWithCountryReducer = (state = {cities: []}, action) => {
    switch (action.type) {
        case CITIY_LIST_WITH_COUNTRY_REQUEST:
          return { loading: true, cities: [] }
        case CITIY_LIST_WITH_COUNTRY_SUCCESS:
          return {  loading: false,  cities: action.payload }
        case CITIY_LIST_WITH_COUNTRY_FAIL:
          return { loading: false, error: action.payload }
        default:
          return state
      }
}

export const paymentMethodListReducer = (state = {paymentMethods: []}, action) => {
  switch (action.type) {
      case PAYMENT_METHOD_LIST_REQUEST:
        return { loading: true, paymentMethods: [] }
      case PAYMENT_METHOD_LIST_SUCCESS:
        return {  loading: false,  paymentMethods: action.payload }
      case PAYMENT_METHOD_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
}
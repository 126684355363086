import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { savePaymentMethod } from '../actions/cartActions'
import { listPaymentMethods } from '../actions/sharedActions'
import { BASE_URL } from '../constants/globalConstants'

const PaymentScreen = ({ history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listPaymentMethods())
  }, [dispatch])

  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const paymentMethodList = useSelector((state) => state.paymentMethodList)
  const { paymentMethods } = paymentMethodList


  if (!shippingAddress) {
    history.push('/shipping')
  }

  const [paymentMethod, setPaymentMethod] = useState('')


  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(savePaymentMethod(paymentMethod))
    history.push('/placeorder')
  }

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />
      <h1>Payment Method</h1>
      <form onSubmit={submitHandler}>
        <div className='form-group'>
          <label>
            <legend>Select Method</legend>
          </label>

          <div className='col m-2'>
            {paymentMethods && paymentMethods.length > 0 && paymentMethods.map((paymentMethod) => (
              <div key={paymentMethod.payment_method_id} className='d-flex gap-2 h6 lead'>
                <input
                  type='radio'
                  className=''
                  label={`${paymentMethod.name}`}
                  id={`${paymentMethod.name}`}
                  name='paymentMethod'
                  value={`${paymentMethod.name}`}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  required
                />

                <label className='mt-1'> {paymentMethod.name}</label>
                {/* <div className='card card-body'>
                  <div className='d-flex'>
                    <img src={`${BASE_URL}${paymentMethod.image_path}`} className='mt-4' style={{ 'height': '60px', 'width': '60px', 'borderRadius': '50%' }} alt={paymentMethod.name} />
                    <div className=''>
                      <ul className='list-group list-group-flush'>
                        <li className='list-group-item'>
                          <strong>Method: </strong>
                          {paymentMethod.name}
                        </li>
                        <li className='list-group-item'>
                          <strong>Account Name: </strong>
                          {paymentMethod.account_name}
                        </li>
                        <li className='list-group-item'>
                          <strong>Account Number: </strong>
                          {paymentMethod.account_number}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            ))}
          </div>
        </div>
        <button type='submit' className='btn btn-dark'>
          Countinue
        </button>
      </form>
    </FormContainer>
  )
}

export default PaymentScreen

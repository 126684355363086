import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { saveShippingAddress } from '../actions/cartActions'
import { listCityWithCountry, listCountries } from '../actions/sharedActions'

const ShippingScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const [address, setAddress] = useState(shippingAddress.address)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [country, setCountry] = useState(shippingAddress.country)
  const [phoneNumber, setPhoneNumber] = useState(shippingAddress.phoneNumber)
  // const [showCountryError, setShowCountryError] = useState(false)
  // const [showCityError, setShowCityError] = useState(false)

  const dispatch = useDispatch()

  // useEffect(() => {
  //    dispatch(listCountries())
  // }, [dispatch])

  // const countryList = useSelector((state) => state.countryList)
  // const {countries} = countryList

  // const cityListWithCountry = useSelector((state) => state.cityListWithCountry)
  // const {cities} = cityListWithCountry

  // const handleCountrySelectChange = (e) => {
  //   if(e.target.value !== 'Select Country') {
  //     setShowCountryError(false)
  //     setCountry(e.target.value)

  //     // Load cities
  //     dispatch(listCityWithCountry(e.target.value))
  //   }
  // }

  // const handleCitySelectChange = (e) => {
  //   if(e.target.value !== 'Select City') {
  //     setCity(e.target.value)
  //   }
  // }

  const submitHandler = (e) => {
    e.preventDefault()
    // if(country === undefined) {
    //   setShowCountryError(true)
    // } else if(city === undefined) {
    //   setShowCityError(true)
    // }
    
    dispatch(saveShippingAddress({ address, city, postalCode, country, phoneNumber }))
    history.push('/payment')
  }

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Shipping</h1>
      <form onSubmit={submitHandler}>
        {/* <div className='form-group'>
          <label htmlFor='country'>Country</label>
          <select className='form-control'  onChange={(e) => handleCountrySelectChange(e)}>
            <option>Select Country</option>
            {countries.length > 0 && countries.map((country) => (
              <option key={country.country_id} value={country.name}>{ country.name }</option>
            ))}
          </select>
          {showCountryError === true && <div className="text-danger">
           Please select a country.
          </div>}
        </div>
        <div className='form-group'>
          <label htmlFor='country'>City</label>
          <select className='form-control'  onChange={(e) => handleCitySelectChange(e)}>
            <option>Select City</option>
            {cities && cities.length > 0 && cities.map((city) => (
              <option key={city.city_id} value={city.name}>{ city.name }</option>
            ))}
          </select>
          {showCityError && <div className="text-danger">
           Please select a city.
          </div>}
        </div> */}
        <div className='form-group'>
          <label htmlFor='address'>Address</label>
          <input
            type='text'
            className='form-control my-1'
            placeholder='Enter address'
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        {/* <div className='form-group'>
          <label htmlFor='postalCode'>Postal Code</label>
          <input
            type='text'
            className='form-control my-1'
            placeholder='Enter postal code'
            value={postalCode}
            required
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div> */}

        <div className='form-group mb-5'>
          <label htmlFor='postalCode'>Phone Number</label>
          <input
            type='text'
            className='form-control my-1'
            placeholder='Enter phone number'
            value={phoneNumber}
            required
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        
        <button type='submit' className='btn btn-dark'>
          Countinue
        </button>
      </form>
    </FormContainer>
  )
}

export default ShippingScreen

import {
  GET_ALL_CATEGORIES_LIST_FAIL,
  GET_ALL_CATEGORIES_LIST_REQUEST,
  GET_ALL_CATEGORIES_LIST_SUCCESS,
  GET_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL,
  GET_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST,
  GET_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS,
  GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_FAIL,
  GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_REQUEST,
  GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_SUCCESS,
  GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL,
  GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST,
  GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS,
} from '../constants/categoryConstants'

export const subCategoriesListReducer = (
  state = { subCategories: [] },
  action
) => {
  switch (action.type) {
    case GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_REQUEST:
      return { ...state, loading: true }
    case GET_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST:
      return { ...state, loading: false }
    case GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_SUCCESS:
      return { loading: false, subCategories: action.payload }
    case GET_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS:
      return { loading: false, subCategories: action.payload }
    case GET_SUB_CATEGORIES_WITH_CATEGORY_NAME_FAIL:
      return { loading: false, error: action.payload }
    case GET_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const subSubCategoriesListReducer = (
  state = { subSubCategories: [] },
  action
) => {
  switch (action.type) {
    case GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_REQUEST:
      return { ...state, loading: true }

    case GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_SUCCESS:
      return { loading: false, subSubCategories: action.payload }

    case GET_SUB_SUB_CATEGORIES_WITH_CATEGORY_ID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const allCategoriesListReducer = ( state = { allCategories: [] }, action ) => {
  switch(action.type) {
    case GET_ALL_CATEGORIES_LIST_REQUEST:
      return { ...state, loading: true }
    case GET_ALL_CATEGORIES_LIST_SUCCESS:
      return {loading: false, allCategories: action.payload}
    case GET_ALL_CATEGORIES_LIST_FAIL:
      return {loading: false, error: action.payload}
    default:
      return state
  }
}

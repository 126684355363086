import React from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../constants/globalConstants'
import Rating from './Rating'

const Product = ({ product }) => {
  return (
    <div className='card my-3 p-3 rounded'>
      <Link to={`/product/${product._id}`}>
        <img src={`${BASE_URL}${product.image}`} alt={product.name} className='img card-img-top' style={{ height: '220px' }} />
      </Link>
      <div className='card-body'>
        <div className='card-title'>
          <strong>{product.name}</strong>
        </div>
        <div className='card-text'>
          <Rating
            value={product.rating}
            text={`${
              product.num_of_reviews == null ? '0' : Math.round(product.num_of_reviews)
            } reviews`}
          />
        </div>
        <p className='card-text h3'>ETB {product.price}</p>
        <Link
          to={`/product/${product.product_id}`}
          className='stretched-link'
        ></Link>
      </div>
    </div>
  )
}

export default Product

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import { getOrderDetails } from '../actions/orderActions'
import { BASE_URL } from '../constants/globalConstants'

const OrderScreen = ({ match }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrderDetails(orderId))
  }, [dispatch, orderId])

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }
    // Calculate prices
    order.itemPrice = addDecimals(
      order.order_items.reduce(
        (acc, item) => acc + item.product.price * item.qty,
        0
      )
    )
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <h1>Order {order.order_id}</h1>
      <div className='row'>
        <div className='col-md-8'>
          <ul className='list-group list-group-flush'>
            <li className='list-group-item'>
              <h2>Shipping</h2>
              <p>
                <strong>Name: {order.user.name}</strong>
              </p>
              <p>
                <strong>
                  Email:{' '}
                  <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                </strong>
              </p>
              <p>
                <strong>Address:</strong>
                {order.shipping_address.address}, {order.shipping_address.city},{' '}
                {order.shipping_address.postalCode},{' '}
                {order.shipping_address.country}
              </p>
              <div className={'alert alert-danger'}>
                  Not Delivered
              </div>
            </li>
            <li className='list-group-item'>
              <h2>Payment Method</h2>
              <div className='card card-body'>
                <ul className='list-group list-group-flush'>
                <li className='list-group-item'> 
                    <strong>Method: </strong>
                    {order.payment_method.name}
                  </li>
                  <div className='alert alert-success'>
                    Paid
                  </div>
                  {/* <li className='list-group-item'> 
                    <strong>Account Name: </strong>
                    {order.payment_method.account_name}
                  </li>
                  <li className='list-group-item'> 
                    <strong>Account Number: </strong>
                    {order.payment_method.account_number}
                  </li> */}
                </ul>
                 
              </div>             
            </li>
            <li className='list-group-item'>
              <h2>Order Items</h2>
              {order.order_items.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ul className='list-group list-group-flush'>
                  {order.order_items.map((item, index) => (
                    <li className='list-group-item' key={index}>
                      <div className='row'>
                        <div className='col-md-1'>
                          <img
                            src={`${BASE_URL}${item.product.image}`}
                            alt={item.product.name}
                            className='img img-fluid rounded'
                          />
                        </div>
                        <div className='col'>
                          <Link
                            to={`/product/${item.product.product_id}`}
                            className='text-dark text-decoration-none'
                          >
                            {item.product.name}
                          </Link>
                        </div>
                        <div className='col-md-4'>
                          {item.qty} x {item.product.price} ={' '}
                          {(item.qty * item.product.price).toFixed(2)}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item'>
                <h2>Order Summary</h2>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Items</div>
                  <div className='col'>ETB {order.itemPrice}</div>
                </div>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Shipping</div>
                  <div className='col'>ETB {order.shipping_price}</div>
                </div>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Tax</div>
                  <div className='col'>ETB {order.tax_price}</div>
                </div>
              </li>
              <li className='list-group-item'>
                <div className='row'>
                  <div className='col'>Total</div>
                  <div className='col'>ETB {order.total_price}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderScreen
